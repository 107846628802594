<template>
	<div class="role-permission">
		<div class="">
			<header class="role-permission-header">
				<div class="header-left">
					<div class="icon"></div>
					<div class="info">
						<p>教研总监</p>
						<span>暂无相关描述</span>
					</div>
				</div>
				<div class="header-right">
					<el-button type="primary" icon="el-icon-circle-plus">添加人员</el-button>
					<el-button icon="el-icon-edit">编辑角色</el-button>
					<el-button icon="el-icon-s-tools">设置状态</el-button>
					<el-button icon="el-icon-delete-solid">删除职务</el-button>
				</div>
			</header>

			<el-tabs v-model="activeName" class="role-permission-tabs">
				<el-tab-pane label="菜单权限" name="1">
					<el-tree
						:data="permissionMenuDataList"
						show-checkbox
						default-expand-all
						:expand-on-click-node="false"
						check-on-click-node
						class="role-permission-tree">
					</el-tree>
				</el-tab-pane>
				<el-tab-pane label="操作权限" name="2">
					<ul class="role-function-type">
						<li class="function-title">
							<div class="function-item">
								<span>功能查询</span>
								<el-checkbox></el-checkbox>
							</div>
						</li>
						<li class="function-items">
							<div class="function-item">
								<span>查询</span>
								<el-checkbox></el-checkbox>
							</div>
							<div class="function-item">
								<span>查看</span>
								<el-checkbox></el-checkbox>
							</div>
							<div class="function-item">
								<span>编辑</span>
								<el-checkbox></el-checkbox>
							</div>
							<div class="function-item">
								<span>删除</span>
								<el-checkbox></el-checkbox>
							</div>
							<div class="function-item">
								<span>查询</span>
								<el-checkbox></el-checkbox>
							</div>
							<div class="function-item">
								<span>导入</span>
								<el-checkbox></el-checkbox>
							</div>
							<div class="function-item">
								<span>导出</span>
								<el-checkbox></el-checkbox>
							</div>
							<div class="function-item">
								<span>同步</span>
								<el-checkbox></el-checkbox>
							</div>
							<div class="function-item"></div>
						</li>
					</ul>
				</el-tab-pane>
				<el-tab-pane label="数据权限" name="3">
					<p class="date-permission-info">
						<strong>数据权限</strong>
						<span>（设置该角色的用户可以操作的数据的范围）</span>
					</p>
					<el-radio-group v-model="permissionDataActiveName">
						<p>
							<el-radio label="0">个人</el-radio>
							<span>只能操作自己和下属的数据</span>
						</p>
						<p>
							<el-radio label="1">所属部门</el-radio>
							<span>能操作自己、下属、和自己所属部门的数据</span>
						</p>
						<p>
							<el-radio label="2">所属部门及下属部门</el-radio>
							<span>所属部门及下属部门 能操作自己、下属和自己所属部门及其子部门的数据</span>
						</p>

						<p>
							<el-radio label="4">全部</el-radio>
							<span>能操作所有数据</span>
						</p>
					</el-radio-group>
				</el-tab-pane>
				<el-tab-pane label="成员列表" name="4">
					<el-table :data="[{}]">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column prop="" label=""></el-table-column>
						<el-table-column prop="" label="姓名"></el-table-column>
						<el-table-column prop="" label="部门"></el-table-column>
						<el-table-column prop="" label="添加时间"></el-table-column>
						<el-table-column prop="" label="操作" class="table-handle" align="center">
							<el-button type="text" class="button-danger">删除</el-button>
						</el-table-column>
					</el-table>

					<div class="table-footer">
						<div class="footer-left">
							<el-button>选择全部</el-button>
							<el-button>反向选择</el-button>
							<el-button>删除选择</el-button>
						</div>

						<div class="footer-right">
							<el-pagination layout="prev, pager, next, sizes"></el-pagination>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>

		<header class="footer">
			<el-button type="primary">保存</el-button>
		</header>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activeName: '4',
			permissionMenuDataList: [
				{
					label: '一级-1',
					children: [
						{
							label: '二级-1',
							children: [
								{ label: '三级-1' },
								{ label: '三级-2' },
								{ label: '三级-3' },
							]
						},
						{
							label: '二级-2'
						},
						{
							label: '二级-3'
						},
					]
				},
				{
					label: '一级-2',
					children: [
						{
							label: '二级-1'
						},
						{
							label: '二级-2'
						},
						{
							label: '二级-3'
						},
					]
				}
			],
			permissionDataActiveName: '0'
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common_c.scss';
.role-permission {
	background: #fff;
	.role-permission-header	{
		display: flex;
		padding: 40px 20px;
		justify-content: space-between;
		border-bottom: 4px solid #f5f6fa;
		.header-left {
			display: flex;
			.icon {
				margin-right: 10px;
				width: 66px;
				height: 66px;
				background: #fb6260;
				border-radius: 50%;
			}
			.info {
				display: flex;
				flex-direction: column;
				justify-content: center;
				> p {
					padding: 4px 0;
					color: #666;
					font-size: 18px;
				}
				> span {
					padding: 4px 0;
					color: #999;
					font-size: 12px;
				}
			}
		}
		.header-right {
			display: flex;
			align-items: center;
		}
	}

	.role-permission-tabs {
		padding: 30px 20px;
		/deep/.role-permission-tree {
			.el-tree-node__content {
				margin-bottom: 10px;
			}
		}

	}

	.role-function-type {
		li {
			display: flex;
			border-top: 1px solid #e9e9e9;
			border-left: 1px solid #e9e9e9;
			&.function-title {
				font-weight: bold;
				background: #f5f5f5;
				.function-item {
					width: 100%;
				}
			}
			&.function-items {
				flex-wrap: wrap;
				justify-content: space-between;
				.function-item {
					width: 33%;
				}
			}
			.function-item {
				padding: 2px 20px;
				display: flex;
				justify-content: space-between;
				flex-grow: 1;
				height: 40px;
				line-height: 40px;
				box-sizing: border-box;
				border-right: 1px solid #e9e9e9;
				border-bottom: 1px solid #e9e9e9;
			}
		}
	}

	#pane-3 {
		.el-radio-group {
			padding: 30px 0;
			> p {
				margin-bottom: 10px;
			}
		}
	}

	#pane-4 {
		.table-footer {
			padding: 10px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.footer {
		padding-bottom: 20px;
	}
}
</style>
